import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Button from '../components/button'

class IndexPage extends React.Component {
  render() {
    const siteTitle = 'Beer time'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `beer`, `genesis guerrero`, `drink`, `brew`]}
        />
        <div style={{ textAlign: 'center' }}>
          <img
            style={{ margin: 0 }}
            src="./pint_of_beer.svg"
            alt="pint of beer"
            height="180px"
          />
        </div>
        <h1>
          Hey people{' '}
          <span role="img" aria-label="wave emoji">
            👋
          </span>
        </h1>
        <p>
          Welcome to your new beer website. I am drinking right now, so come
          back later.
        </p>
        <p>Or take a look to my web development blog</p>
        <a href="https://blog.genesisguerrero.com">
          <Button marginTop="35px">Go to Blog</Button>
        </a>
      </Layout>
    )
  }
}

export default IndexPage
